import React, { useState } from "react"
import { Link } from "gatsby"
import styled, { withTheme } from "styled-components"
import { useSpring } from "react-spring"
import Headroom from "react-headroom"

import Menu from "./menu"
import MobileMenu from "./mobile-menu"

import Logo from "../../images/logo.svg"

interface IToggle {
  handleToggle: any
}

const MobileToggle = ({ handleToggle }: IToggle) => {
  const Toggle = styled.div`
    width: 2.5rem;
    height: 1.5rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    cursor: pointer;

    @media only screen and (min-width: 769px) {
      display: none;
    }

    &:before,
    &:after,
    div {
      content: "";
      position: absolute;
      right: 0;
      height: 2px;
      background: ${props => props.theme.colors.light};
      display: block;
    }
    &:before {
      width: 100%;
      top: 0;
    }
    &:after {
      width: 100%;
      bottom: 0;
    }

    div {
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  `

  return (
    <Toggle onClick={handleToggle}>
      <div />
    </Toggle>
  )
}

const Header = ({ theme, headerClass }: any) => {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false)

  const handleToggle = () => {
    setToggleMenu(!toggleMenu)
  }

  const navAnimation = useSpring({
    transform: toggleMenu
      ? `translate3d(0, 0, 0) scale(1)`
      : `translate3d(100%, 0%, 0) scale(1)`,
    config: {
      tension: 450,
    },
  })

  const Head = styled.header`
    background: ${theme.colors.primary};
    box-sizing: border-box;
    padding: 1.5rem 2% 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: ${theme.breakpoints.phone}) {
      padding: 1.5rem 5% 0 5%;
    }

    svg {
      fill: ${theme.colors.light};
      //width: 150px;
      transition: 0.2s;
      @media only screen and (max-width: ${theme.breakpoints.phone}) {
        top: -10px;
        position: relative;
      }
    }

    nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: ${theme.breakpoints.tablet}) {
        display: none;
      }
    }
  `

  return (
    <React.Fragment>
      <MobileMenu style={navAnimation} handleToggle={handleToggle} />
      <Headroom className={headerClass}>
        <Head>
          <Link to="/#home" aria-label="Home">
            <Logo />
          </Link>
          <Menu />
          <MobileToggle handleToggle={handleToggle} />
        </Head>
      </Headroom>
    </React.Fragment>
  )
}

export default withTheme(Header)
