import React from "react"
import styled from "styled-components"
import { animated } from "react-spring"

import Navigation from "./menu"

const CloseButton = ({ handleToggle }: any) => {
  const Close = styled.div`
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 2rem;
    right: 1rem;
    cursor: pointer;
  `

  const Bar = styled.div`
    width: 100%;
    height: 2px;
    background: ${props => props.theme.colors.light};
    &:first-of-type {
      transform: rotate(-45deg);
    }
    &:last-of-type {
      transform: rotate(45deg);
    }
  `

  return (
    <Close onClick={handleToggle}>
      <Bar />
      <Bar />
    </Close>
  )
}

const MobileMenu = ({ handleToggle, style }: any) => {
  const Wrapper = styled(animated.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2000;
    overflow: scroll;

    ul {
      padding: 0;
    }

    li {
      display: block;
      text-align: center;
    }
    a {
      font-size: 30px;
      line-height: 62px;
      color: ${props => props.theme.colors.light};
      &.active {
        color: ${props => props.theme.colors.light};
        border-color: ${props => props.theme.colors.light};
      }
    }
  `
  // useBodyScrollLock()
  return (
    <Wrapper style={style} onClick={handleToggle}>
      <CloseButton handleToggle={handleToggle} />
      <Navigation />
    </Wrapper>
  )
}

export default MobileMenu
