import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Navigation = styled.ul`
  list-style: none;
  padding: 0;
  margin: -1rem 0 0;
  a {
    font-size: 14px;
    line-height: 1;
    color: ${props => props.theme.colors.light};
    text-decoration: none;
    padding: 0.25rem;
    border-bottom: 2px solid transparent;
    transition: 0.2s;
    &:hover,
    &[aria-current="page"],
    &.active {
      border-bottom: 2px solid ${props => props.theme.colors.secondary};
    }
  }
  li {
    display: inline-block;
    margin: 1rem;
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      a {
        color: ${props => props.theme.colors.secondary}; 
        font-size: 1rem;  
      }
    }
  }
`

const Button = styled(Link)`
  font-size: 2rem;
  font-family: ${props => props.theme.fontBold};
  padding: 12px 40px !important;
  margin: 2rem 0 2rem 4rem;
  background: ${props => props.theme.colors.secondary};
  border: 2px solid ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary} !important;
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    background: transparent;
    border: 2px solid ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white} !important;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 0.5rem 2rem;
    font-size: 1rem !important;
    margin: 0;
  }
`

const Menu = () => {
  return (
    <nav>
      <Navigation>
        <li>
          <Link to="#about" activeClassName="active">
            The Book
          </Link>
        </li>
        <li>
          <Link to="#author" activeClassName="active">
            The author
          </Link>
        </li>
        <li>
          <Link to="#contact" activeClassName="active">
            Get in touch
          </Link>
        </li>
        <li>
          <Button to="#contact">
            Register Interest
          </Button>
        </li>
      </Navigation>
    </nav>
  )
}

export default Menu
