import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import "./fonts.css"

import Header from "./header"
import Footer from "./footer"

if (typeof window !== "undefined") {
  // tslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    offset: 0,
  })
}

const theme = {
  breakpoints: {
    desktop: `992px`,
    large: `1140px`,
    phone: `576px`,
    tablet: `768px`,
  },
  colors: {
    primary: `#133645`,
    secondary: `#FDEC4F`,
    accent: `#D33544`,
    light: `#DCECDD`,
    dark: `#3C3C3B`,
    black: `#000000`,
    white: `#ffffff`,
  },
  font: `"Brown", sans-serif`,
  fontLight: `"Brown Light", sans-serif`,
  fontBold: `"Brown Bold", sans-serif`,
  fontThin: `"Brown Thin", sans-serif`,
}

const Styles = createGlobalStyle`
  body{
    font-family: ${theme.fontLight};
    font-size: 20px;
    line-height: 1.35;
    color: ${theme.colors.dark};
    margin: 0;
  }

  h1 {
    font-family: ${theme.font};
  }

  p {
    font-size: 16px;
  }

  h2 {
    font-size: 50px;
    color: ${theme.colors.accent};
    @media screen and (max-width: ${theme.breakpoints.phone}) {
      font-size: 30px;
      line-height: 1;
    }
  }

  h4 {
    font-family: ${theme.fontBold};
  }

  a {
    color: ${theme.colors.primary};
  }

  blockquote {
    //background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDciIGhlaWdodD0iNDIiPiAgPGRlZnM+ICAgIDxwYXRoIGlkPSJhIiBkPSJNLjI5LjM1aDIzLjN2NDEuNTFILjI5eiIvPiAgICA8cGF0aCBpZD0iYyIgZD0iTTAgLjM1aDIyLjkxdjQxLjUxSDB6Ii8+ICA8L2RlZnM+ICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMykiPiAgICAgIDxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj4gICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4gICAgICA8L21hc2s+ICAgICAgPHBhdGggZmlsbD0iIzAwQjFCMiIgZD0iTTExLjUgMTkuOTRjOS42NiAwIDEyLjA5IDYuNDcgMTIuMDkgMTEuMiAwIDUuOTgtNC41NSAxMC43Mi0xMS4yOSAxMC43Mi03LjEgMC0xMi01LjI3LTEyLTEyLjNDLjMgMTYuOTMgNy44NSA5LjMgMjIuNDEuMzZjMCAwLTEwLjM4IDExLjY5LTEwLjkzIDE5LjU5IiBtYXNrPSJ1cmwoI2IpIi8+ICAgIDwvZz4gICAgPGc+ICAgICAgPG1hc2sgaWQ9ImQiIGZpbGw9IiNmZmYiPiAgICAgICAgPHVzZSB4bGluazpocmVmPSIjYyIvPiAgICAgIDwvbWFzaz4gICAgICA8cGF0aCBmaWxsPSIjMDBCMUIyIiBkPSJNMTEuMDEgMTkuOTRjOS41MSAwIDExLjkgNi40NyAxMS45IDExLjIgMCA1Ljk4LTQuNDcgMTAuNzItMTEuMSAxMC43MkM0LjgzIDQxLjg2IDAgMzYuNTkgMCAyOS41NiAwIDE2LjkzIDcuNDQgOS4zIDIxLjc2LjM2YzAgMC0xMC4yMSAxMS42OS0xMC43NSAxOS41OSIgbWFzaz0idXJsKCNkKSIvPiAgICA8L2c+ICA8L2c+PC9zdmc+);
    background-position: top left;
    background-repeat: no-repeat;
    margin: 2rem 0;
    padding: 0.5rem 0.5rem 0.5rem 60px;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.2;
  }

  svg {
    max-width: 100%;
  }

  .headroom--pinned header, .headroom--unpinned header {
    padding: 1rem 2% 0.5rem;
    @media only screen and (max-width: ${theme.breakpoints.phone}) {
      padding: 0 5% 0.5rem;
    }
    svg {
      //width: 150px; 
    } 
  }
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    .home > .headroom--unfixed {
      // svg {
      //   transition: 0.5s;
      //   transform-origin: 100% 0% 0px;
      //   transform: translate3d(49vw, 15vh, 0) scale3d(2, 2, 1);
      // }
    }
  }
`

interface IProps {
  children: React.ReactNode
  headerClass?: string
}

const Layout = ({ children, headerClass }: IProps) => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Styles />
        <Header headerClass={headerClass} />
        <main>{children}</main>
        <Footer />
      </React.Fragment>
    </ThemeProvider>
  )
}

export default Layout
