import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 990px;
  max-width: 90%;
  margin: 0 auto;
`

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`

const HalfPage = styled.div`
  width: 48%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

const FullPage = styled.div`
  width: 100%;
`

const ThirdPage = styled.div`
  width: 32%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

const TwoThirdPage = styled.div`
  width: 68%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

const OneQuarterPage = styled.div`
  width: 24%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

const ThreeQuarterPage = styled.div`
  width: 70%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

interface IProps {
  children: React.ReactNode
}

const Container = ({ children }: IProps) => {
  return <Wrapper>{children}</Wrapper>
}

export default Container

export const Row = ({ children }: IProps) => (
  <ContainerRow>{children}</ContainerRow>
)

export const Half = ({ children }: IProps) => <HalfPage>{children}</HalfPage>

export const Third = ({ children }: IProps) => <ThirdPage>{children}</ThirdPage>

export const TwoThird = ({ children }: IProps) => <TwoThirdPage>{children}</TwoThirdPage>

export const OneQuarter = ({ children }: IProps) => <OneQuarterPage>{children}</OneQuarterPage>

export const ThreeQuarter = ({ children }: IProps) => <ThreeQuarterPage>{children}</ThreeQuarterPage>

export const Full = ({ children }: IProps) => <FullPage>{children}</FullPage>
