/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface IProps {
  description: string
  lang: string
  meta: []
  title: string
}

const Seo = ({ description, lang, meta, title }: IProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://res.cloudinary.com/yumyoshojin/image/upload/v1638298915/alan/RH_Book_Website_Meta_Image.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@raconteur`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      script={[
        {
          type: `application/ld+json`,
          innerHTML: `{"@context":"http://schema.org","@type":"WebSite","name":"Marketing: The Bottom Line","alternateName":"Marketing: The Bottom Line","url":"https://www.marketingthebottomline.co.uk","description":"How you can move marketing from a perceived cost centre into a strategic driver of profit? Agency CEO, and former salesman, Richard Hadler explains.","image":"https://res.cloudinary.com/yumyoshojin/image/upload/v1638298915/alan/RH_Book_Website_Meta_Image.jpg"}`,
        },
        {
          type: `application/ld+json`,
          innerHTML: `{"@context":"https://schema.org","@type":"NewsMediaOrganization","name":"Raconteur Media","alternateName":"Raconteur","url":"https://www.raconteur.net/","logo":"https://live-raconteur.pantheonsite.io//wp-content/uploads/2016/10/raconteur-logo.png","contactPoint":{"@type":"ContactPoint","telephone":"+442038773827","contactType":"customer service","areaServed":"GB","availableLanguage":"en"},"sameAs":["https://www.facebook.com/raconteur.net/","https://twitter.com/raconteur","https://www.linkedin.com/company/raconteur-media/"]}`,
        },
      ]}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
