import React from "react"
import styled, { withTheme } from "styled-components"
import { Link } from "gatsby"

interface IProps {
  theme: {
    colors: {
      primary: string
      light: string
    }
  }
}

const Footer = ({ theme }: IProps) => {
  const Foot = styled.footer`
    background: ${theme.colors.secondary};
    width: 100%;
    //margin-top: 5rem;
    color: ${theme.colors.light};
    box-sizing: border-box;
    padding: 1rem 5%;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    a {
      color: ${theme.colors.light};
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        opacity: 0.8;
      }
    }

    address {
      font-style: normal;
      max-width: 90%;

      a:hover {
        opacity: 1;
        border-bottom: 2px solid ${props => props.theme.colors.secondary};
      }
    }

    nav {
      margin: 0 -10px;
      max-width: 90%;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      display: inline-block;
      padding: 10px;
    }
  `

  return (
    <Foot>
      {/* <address>
        <a href="https://www.raconteur.net/" target="_blank" rel="noopener">
          Raconteur Media Ltd.
        </a>{" "}
        &copy; {new Date().getFullYear()} All rights reserved.
        <br />
        2nd Floor, Portsoken House, 155-157 Minories, London, EC3N 1LJ
      </address>
      <nav>
        <ul>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </li>
        </ul>
      </nav> */}
    </Foot>
  )
}

export default withTheme(Footer)
